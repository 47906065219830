import * as React from "react";
import Layout from "../components/layout";
import {StaticImage} from "gatsby-plugin-image";
import Seo from "../components/seo";


const IndexPage = () => {
  return (
    <Layout pageTitle="Home page">
        This is the home page of the Blog
        <StaticImage alt="Dmitry Lukashin" src="../images/profile.jpg" />
    </Layout>
  )
}
export const Head = () => <Seo title="Home Page" />

export default IndexPage

